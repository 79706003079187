import { IonPage, IonContent } from "@ionic/react";
import "./WebSign.scss";
import Toolbar from "../components/Toolbar";
import { t } from "i18next";
import { RouteComponentProps } from "react-router";
import { useEffect, useState } from "react";

interface ParamsDetails
  extends RouteComponentProps<{
    type: string;
    id: string;
  }> {}

function Viewer({
  url,
  onBlobLoad,
}: {
  url: string;
  onBlobLoad: (blob: Blob) => void;
}) {
  const [src, setSrc] = useState<string | null>(null);

  useEffect(() => {
    fetch(`${localStorage.getItem("server_url")}/${url}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access")}`,
      },
      method: "POST",
      body: '{"password":"bm4bL3Zz"}',
    })
      .then((response) => response.blob())
      .then((blob) => {
        const blobUrl = URL.createObjectURL(blob);
        setSrc(blobUrl);
        onBlobLoad(blob); // Pass the blob to parent component
      });
  }, [url, onBlobLoad]);

  return src ? (
    <embed src={src} type="application/pdf" width="100%" height="800px" />
  ) : null;
}

const PDFPreview: React.FC<ParamsDetails> = ({ match }) => {
  const [pdfBlob, setPdfBlob] = useState<Blob | null>(null);
  const urlPath = `/api/request_report/?report=${match.params.type}&report-id=${match.params.id}`;

  const handleDownload = () => {
    if (pdfBlob) {
      // Create a URL for the blob
      const url = window.URL.createObjectURL(pdfBlob);
      // Create a temporary anchor element
      const link = document.createElement("a");
      link.href = url;
      // Set the download filename
      link.download = `document-${match.params.type}-${match.params.id}.pdf`;
      // Append to body, click, and remove
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      // Clean up the URL
      window.URL.revokeObjectURL(url);
    }
  };

  return (
    <IonPage>
      <Toolbar title={t("Document viewer")} />
      <IonContent fullscreen>
        <button
          onClick={handleDownload}
          disabled={!pdfBlob}
          style={{
            backgroundColor: "#4CAF50",
            border: "none",
            color: "white",
            padding: "10px 20px",
            textAlign: "center",
            textDecoration: "none",
            display: "inline-block",
            fontSize: "16px",
            width: "100%",
            margin: "4px 2px",
            cursor: "pointer",
            borderRadius: "4px",
          }}
        >
          Descargar fichero
        </button>
        <div id="pdf">
          <Viewer url={urlPath} onBlobLoad={setPdfBlob} />
        </div>
      </IonContent>
    </IonPage>
  );
};

export default PDFPreview;
