import "./Notifs.scss";

import Toolbar from "../components/Toolbar";
import {
  IonIcon,
  IonPage,
  IonButtons,
  IonContent,
  IonButton,
  useIonAlert,
  IonToast,
  AlertInput,
  useIonModal,
} from "@ionic/react";
import {
  calendarClearOutline,
  timeOutline,
  locationOutline,
  paperPlaneOutline,
  mailOpenOutline,
  notificationsOutline,
  documentAttachOutline,
  imageOutline,
} from "ionicons/icons";

import { useAppSelector, useAppDispatch } from "../app/hooks";
import { useEffect, useState } from "react";
import {
  acceptAppointment,
  rejectAppointment,
  initiateAppointmentSignature,
  clearSignature,
} from "../actions/appointmentManagement";
import { selectAppointments } from "../features/appointment/appointmentSlice";
import {
  selectPendingSignature,
  selectIsSignatureComplete,
} from "../features/notification/notificationSlice";

import { StatusNotification } from "../shared/constants/notifications";
import { useTranslation } from "react-i18next";

import ModalCreateNotification from "../components/modals/ModalCreateNotification";
import ModalNotificationDetails from "../components/modals/ModalNotificationDetails";
import { OverlayEventDetail } from "@ionic/react/dist/types/components/react-component-lib/interfaces";
import ModalNotificationFiles from "../components/modals/ModalNotificationFiles";
import { selectNotifications } from "../features/notification/notificationSlice";
import { getNotifications } from "../actions/notficationsManagement";

import { useIonRouter } from "@ionic/react";

interface INotificationCounter {
  programmed: number;
  confirmed: number;
  refused: number;
  sent: number;
  received: number;
}

const Notifs: any = () => {
  const [t] = useTranslation("global");
  const navigation = useIonRouter();
  const dispatch = useAppDispatch();

  const rejectReasons: AlertInput[] = [
    {
      label: `${t("Reprogramación")}`,
      type: "radio",
      value: "1",
    },
    {
      label: `${t("Renuncia voluntaria")}`,
      type: "radio",
      value: "2",
    },
    {
      label: `${t("Ausencia o vacaciones")}`,
      type: "radio",
      value: "3",
    },
    {
      label: `${t("Baja laboral")}`,
      type: "radio",
      value: "4",
    },
  ];

  const healthAppointments = useAppSelector(selectAppointments);
  const pendingSignature = useAppSelector(selectPendingSignature);
  const isSignatureComplete = useAppSelector(selectIsSignatureComplete);

  const [presentRejectAlert] = useIonAlert();
  const [presentRejectReasonAlert] = useIonAlert();
  const [presentConfirmAppointment] = useIonAlert();

  const [notificationCounter, setNotificationCounter] =
    useState<INotificationCounter>({
      programmed: 0,
      confirmed: 0,
      refused: 0,
      sent: 0,
      received: 0,
    });

  const [needsRejectReason, setNeedsRejectReason] = useState(false);

  const handleAcceptAppointment = ({
    id,
    appointment_type,
  }: {
    id: number;
    appointment_type: string;
  }) => {
    // dispatch( acceptAppointment({id, appointment_type}))
    dispatch(
      initiateAppointmentSignature({
        type: "accept",
        id,
        appointment_type,
      }),
    );

    navigation.push(`/firmar/report/consultation_consent/${id}`);
    // navigation.push('/firmar/report/info_sheet_workers/8'); debug
  };

  const handleRejectAppointment = ({
    id,
    appointment_type,
    reject_reason,
    reject_extra_reason,
  }: {
    id: number;
    appointment_type: string;
    reject_reason: string;
    reject_extra_reason: string;
  }) => {
    if (reject_reason) {
      // dispatch(rejectAppointment({id, appointment_type, reject_reason, reject_extra_reason}))
      dispatch(
        initiateAppointmentSignature({
          type: "reject",
          id,
          appointment_type,
          reject_reason,
          reject_extra_reason,
        }),
      );
      console.warn(id, appointment_type, reject_reason, reject_extra_reason);
      navigation.push(`/firmar/report/consultation_renounce/${id}`);
    }
  };

  useEffect(() => {
    const handleSignatureCompletion = async () => {
      if (isSignatureComplete && pendingSignature) {
        try {
          if (pendingSignature.type === "accept") {
            await dispatch(
              acceptAppointment({
                id: pendingSignature.id,
                appointment_type: pendingSignature.appointment_type,
              }),
            );
          } else if (pendingSignature.type === "reject") {
            await dispatch(
              rejectAppointment({
                id: pendingSignature.id,
                appointment_type: pendingSignature.appointment_type,
                reject_reason: pendingSignature.reject_reason!,
                reject_extra_reason: pendingSignature.reject_extra_reason!,
              }),
            );
          }
        } catch (error) {
          console.error("Error processing signature completion:", error);
        } finally {
          // Make sure we always clear the pending signature state
          dispatch(clearSignature());
        }
      }
    };
    
    handleSignatureCompletion();
  }, [isSignatureComplete, pendingSignature, dispatch]);

  useEffect(() => {
    const counters = healthAppointments.reduce(
      (acc, appointment) => {
        switch (appointment.status) {
          case "programmed":
            acc.programmed++;
            break;
          case "confirmed":
            acc.confirmed++;
            break;
          case "refused":
            acc.refused++;
            break;
        }
        return acc;
      },
      {
        programmed: 0,
        confirmed: 0,
        refused: 0,
      },
    );

    const { programmedCounter, confirmedCounter, refusedCounter } = {
      programmedCounter: counters.programmed,
      confirmedCounter: counters.confirmed,
      refusedCounter: counters.refused,
    };

    setNotificationCounter((prevNotificationCounter) => {
      return {
        received: prevNotificationCounter.received,
        sent: prevNotificationCounter.sent,
        programmed: programmedCounter,
        confirmed: confirmedCounter,
        refused: refusedCounter,
      };
    });

    return () => {};
  }, [healthAppointments]);

  const rejectAppointmentExtraReason = ({
    id,
    appointment_type,
    reject_reason,
  }: {
    id: number;
    appointment_type: string;
    reject_reason: string;
  }) => {
    if (reject_reason) {
      presentRejectReasonAlert({
        header: `${t("Comentario sobre el motivo de rechazo")}`,
        buttons: [
          {
            text: `${t("Enviar")}`,
            handler: (reject_extra_reason) =>
              handleRejectAppointment({
                id,
                appointment_type,
                reject_reason,
                reject_extra_reason,
              }),
          },
          {
            text: `${t("Cancelar")}`,
            role: "cancel",
          },
        ],
        inputs: [
          {
            type: "textarea",
            placeholder: `${t("Ponga un comentario aquí, por favor")}`,
          },
        ],
      });
    } else {
      setNeedsRejectReason(true);
    }
  };

  const confirmAppointment = ({
    id,
    appointment_type,
  }: {
    id: number;
    appointment_type: string;
  }) => {
    presentConfirmAppointment({
      header: `${t("¿Seguro que quiere confirmar el/la")} ${appointment_type}?`,
      subHeader: `${t("Esta acción es irreversible")}`,
      buttons: [
        {
          text: `${t("Cancelar")}`,
          role: "cancel",
        },
        {
          text: `${t("Confirmar")}`,
          role: "confirm",
          handler: () => handleAcceptAppointment({ id, appointment_type }),
        },
      ],
    });
  };

  const rejectAppointmentReason = ({
    id,
    appointment_type,
  }: {
    id: number;
    appointment_type: string;
  }) => {
    presentRejectAlert({
      header: `${t("Elija la razón por la que rechaza el/la")} ${appointment_type}`,
      buttons: [
        {
          text: `${t("Rechazar cita")}`,
          handler: (reject_reason) =>
            rejectAppointmentExtraReason({
              id,
              appointment_type,
              reject_reason,
            }),
        },
        {
          text: `${t("Cancelar")}`,
          role: "cancel",
        },
      ],
      inputs: rejectReasons,
    });
  };

  function filterNotifications(e: any) {
    e.target.classList.contains("active")
      ? e.target.classList.remove("active")
      : e.target.classList.add("active");
    const parent = e.target.parentElement.parentElement;

    const activeNotifications = parent.querySelectorAll(
      ".notifs__title.active",
    );
    const activeTags = Array.from(activeNotifications).map(
      (x) => (x as HTMLElement).dataset.status,
    );

    const notifications = parent.querySelectorAll(".notif");
    notifications.forEach((n: any) => n.classList.remove("inactive"));

    if (activeTags.length > 0) {
      notifications.forEach((n: any) => {
        const status = (n as HTMLElement).dataset.status;
        if (!activeTags.includes(status)) {
          n.classList.add("inactive");
        }
      });
    }
  }

  function getRejectReason(index: string) {
    const reason = rejectReasons.find((r) => r.value === index);
    return reason?.label;
  }

  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (index: number) => {
    setActiveTab(index);
  };

  const [presentCreateNotification, dismissCreateNotification] = useIonModal(
    ModalCreateNotification,
    {
      onDismiss: (data: string, role: string) =>
        dismissCreateNotification(data, role),
    },
  );

  function openCreateNotificationModal() {
    presentCreateNotification({
      onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {},
    });
  }

  const [activeNotification, setActiveNotification] = useState({});

  const [presentNotificationDetails, dismissNotificationDetails] = useIonModal(
    ModalNotificationDetails,
    {
      notification: activeNotification,
      onDismiss: (data: string, role: string) =>
        dismissNotificationDetails(data, role),
    },
  );

  function openNotificationDetailsModal(notification: any) {
    setActiveNotification(notification);
    presentNotificationDetails({
      onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {},
    });
  }

  const [activeNotificationFiles, setActiveNotificationFiles] = useState({});

  const [presentNotificationFiles, dismissNotificationFiles] = useIonModal(
    ModalNotificationFiles,
    {
      data: activeNotificationFiles,
      onDismiss: (data: string, role: string) =>
        dismissNotificationFiles(data, role),
    },
  );

  function openNotificationFilesModal(data: any) {
    setActiveNotificationFiles(data);

    presentNotificationFiles({
      onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {},
    });
  }

  useEffect(() => {
    dispatch(getNotifications());
  }, [dispatch]);
  const notifications = useAppSelector(selectNotifications);

  useEffect(() => {
    const sentCounter = notifications.reduce((accumulator, obj) => {
      return obj.received ? accumulator : accumulator + 1;
    }, 0);
    const receivedCounter = notifications.reduce((accumulator, obj) => {
      return obj.received ? accumulator + 1 : accumulator;
    }, 0);

    setNotificationCounter((prevNotificationCounter) => {
      return {
        received: receivedCounter,
        sent: sentCounter,
        programmed: prevNotificationCounter.programmed,
        confirmed: prevNotificationCounter.confirmed,
        refused: prevNotificationCounter.refused,
      };
    });

    return () => {};
  }, [notifications]);

  function formatDate(date: string, type = "sent") {
    if (date) {
      const splitString = type === "sent" ? " " : "T";
      const [datePart, timePart] = date.split(splitString);
      const [year, month, day] = datePart.split("-");

      let formattedDate = `${day}-${month}-${year}`;

      if (timePart) {
        const [hour, minute] = timePart.split(":");
        formattedDate += ` ${hour}:${minute}`;
      }

      return formattedDate;
    }
  }

  return (
    <IonPage>
      <Toolbar title={t("Notificaciones / Comunicados")} />
      <IonContent fullscreen>
        <div className="notifs">
          <div className="notifs__wrap">
            <ul className="tabs">
              <li className={`tabs__li ${0 === activeTab ? "active" : ""}`}>
                <button
                  onClick={() => handleTabClick(0)}
                  type="button"
                  className="tabs__button"
                >
                  <IonIcon
                    aria-hidden="true"
                    ios={notificationsOutline}
                    md={notificationsOutline}
                  />
                  {t("Notificaciones")}
                </button>
              </li>
              <li className={`tabs__li ${1 === activeTab ? "active" : ""}`}>
                <button
                  onClick={() => handleTabClick(1)}
                  type="button"
                  className="tabs__button"
                >
                  <IonIcon
                    aria-hidden="true"
                    ios={paperPlaneOutline}
                    md={paperPlaneOutline}
                  />
                  {t("Comunicados")}
                </button>
              </li>
            </ul>
            <div
              className={`notifs__column notifs__column--three ${0 === activeTab ? "active" : ""}`}
            >
              <div className="notifs__head">
                <button
                  type="button"
                  title={t("Filtrar por") + " " + t("Pendientes")}
                  onClick={filterNotifications}
                  data-status="programmed"
                  className="notifs__title pending"
                >
                  <span className="notifs__title-text">{t("Pendientes")}</span>
                  <span className="notifs__title-num">{`${notificationCounter.programmed}`}</span>
                </button>
                <button
                  type="button"
                  title={t("Filtrar por") + " " + t("Confirmadas")}
                  onClick={filterNotifications}
                  data-status="confirmed"
                  className="notifs__title accepted"
                >
                  <span className="notifs__title-text">{t("Confirmadas")}</span>
                  <span className="notifs__title-num">{`${notificationCounter.confirmed}`}</span>
                </button>
                <button
                  type="button"
                  title={t("Filtrar por") + " " + t("Rechazadas")}
                  onClick={filterNotifications}
                  data-status="refused"
                  className="notifs__title denied"
                >
                  <span className="notifs__title-text">{t("Rechazadas")}</span>
                  <span className="notifs__title-num">{`${notificationCounter.refused}`}</span>
                </button>
              </div>
              {healthAppointments && (
                <div className="notifs__body">
                  {healthAppointments.map((appointment) => (
                    <div
                      key={appointment.id}
                      className={`notif ${StatusNotification[appointment.status]}`}
                      data-status={appointment.status}
                    >
                      <div className="notif__head">
                        <div className="notif__title">
                          <p className="notif__title-text">
                            <span>
                              {appointment.appointment_type}{" "}
                              {appointment.status === "refused" &&
                              appointment.appointment_decline_reason
                                ? ` | ${getRejectReason(appointment.appointment_decline_reason)}`
                                : ""}
                            </span>
                            {appointment.main_reason}
                          </p>
                          {appointment.status === "programmed" && (
                            <IonButtons>
                              <IonButton
                                title={t("Rechazar cita")}
                                className="request__button cancel"
                                onClick={() =>
                                  rejectAppointmentReason(appointment)
                                }
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="26"
                                  height="26"
                                  fill="currentColor"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                </svg>
                              </IonButton>
                              <IonButton
                                title={t("Confirmar cita")}
                                className="request__button"
                                onClick={() => confirmAppointment(appointment)}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="26"
                                  height="26"
                                  fill="currentColor"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z" />
                                </svg>
                              </IonButton>
                            </IonButtons>
                          )}
                        </div>

                        <p className="notif__date">
                          <span>
                            <IonIcon
                              aria-hidden="true"
                              className="menu_notif__icon"
                              ios={calendarClearOutline}
                              md={calendarClearOutline}
                            />
                            {`${appointment.date.split("T")[0].split("-")[2]}/${appointment.date.split("T")[0].split("-")[1]}/${appointment.date.split("T")[0].split("-")[0]}`}
                          </span>
                          <span>
                            <IonIcon
                              aria-hidden="true"
                              className="menu_notif__icon"
                              ios={timeOutline}
                              md={timeOutline}
                            />
                            {`${appointment.date.split("T")[1].substring(0, appointment.date.split("T")[1].length - 3)}`}
                          </span>
                        </p>
                      </div>
                      <div className="notif__body">
                        <div className="notif__props">
                          <div className="notif__prop">
                            <IonIcon
                              aria-hidden="true"
                              ios={locationOutline}
                              md={locationOutline}
                            ></IonIcon>
                            {`${appointment.medical_center}`}
                          </div>
                          <div className="notif__prop">
                            <p className="notif__p">
                              <span className="notif__span">
                                {t("Solicitado por")}:
                              </span>
                              {`${appointment.medic}`}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
            <div
              className={`notifs__column notifs__column--two ${1 === activeTab ? "active" : ""}`}
            >
              <div className="fixed-button-container fixed-button-container--notif">
                <button
                  onClick={openCreateNotificationModal}
                  className="button-base solid notif__create"
                  title={t("Crear un comunicado")}
                >
                  {t("Crear un comunicado")}
                </button>
              </div>
              <div className="notifs__head">
                <button
                  type="button"
                  title={t("Filtrar por") + " " + t("Enviadas")}
                  onClick={filterNotifications}
                  data-status="sent"
                  className="notifs__title pending"
                >
                  <span className="notifs__title-text">{t("Enviadas")}</span>
                  <span className="notifs__title-num">{`${notificationCounter.sent}`}</span>
                </button>
                <button
                  type="button"
                  title={t("Filtrar por") + " " + t("Confirmadas")}
                  onClick={filterNotifications}
                  data-status="received"
                  className="notifs__title accepted"
                >
                  <span className="notifs__title-text">{t("Confirmadas")}</span>
                  <span className="notifs__title-num">{`${notificationCounter.received}`}</span>
                </button>
              </div>
              {notifications && (
                <div className="notifs__body">
                  {[...notifications]
                    .sort(
                      (a, b) =>
                        new Date(b.sent_datetime).getTime() -
                        new Date(a.sent_datetime).getTime(),
                    )
                    .map((notification) => (
                      <div
                        className={`notif ${notification.received ? "received" : "sent"}`}
                        data-status={
                          notification.received ? "received" : "sent"
                        }
                      >
                        <div className="notif__title">
                          <p className="notif__title-text">
                            <span>
                              {formatDate(notification.sent_datetime, "sent")}
                            </span>
                            {notification.subject}
                          </p>
                          <div
                            className={`request__button ${notification.received ? "" : "icon"}`}
                          >
                            <IonIcon
                              aria-hidden="true"
                              ios={
                                notification.received
                                  ? mailOpenOutline
                                  : paperPlaneOutline
                              }
                              md={
                                notification.received
                                  ? mailOpenOutline
                                  : paperPlaneOutline
                              }
                            />
                          </div>
                        </div>
                        <p className="notif__date">
                          <span>
                            {t("Estado")}:{" "}
                            {notification.received ? "Recibida" : "Enviada"}
                          </span>
                          {notification.received &&
                            notification.received_datetime && (
                              <span>
                                {formatDate(
                                  notification.received_datetime,
                                  "received",
                                )}
                              </span>
                            )}
                        </p>
                        <div className="notif__body">
                          <div className="notif__props">
                            <div className="notif__prop">
                              <span className="notif__span">
                                {t("Departamento")}:
                              </span>
                              {notification.department}
                            </div>
                            <div className="notif__prop">
                              <span className="notif__span">{t("Tipo")}:</span>
                              {notification.type}
                            </div>
                            {notification.received && notification.answer && (
                              <div className="notif__prop">
                                <div className="notif__span notif__span--block">
                                  {t("Respuesta")}:
                                </div>
                                {notification.answer}
                              </div>
                            )}
                          </div>
                          {notification.images &&
                          notification.images.length === 0 ? (
                            <div className="file no-hover">
                              <span className="file__icon">
                                <IonIcon
                                  aria-hidden="true"
                                  ios={imageOutline}
                                  md={imageOutline}
                                ></IonIcon>
                              </span>
                              <p className="file__info">
                                <span className="file__type">
                                  {t("No hay imágenes adjuntas")}
                                </span>
                              </p>
                            </div>
                          ) : (
                            <div
                              className="file"
                              onClick={() => {
                                openNotificationFilesModal({
                                  type: "images",
                                  files: notification.images,
                                });
                              }}
                            >
                              <span className="file__icon">
                                <IonIcon
                                  aria-hidden="true"
                                  ios={imageOutline}
                                  md={imageOutline}
                                ></IonIcon>
                              </span>
                              <p className="file__info">
                                <span className="file__type">
                                  {notification.images &&
                                    notification.images.length}{" "}
                                  {notification.images &&
                                  notification.images.length === 1
                                    ? t("imagen adjunta")
                                    : t("imágenes adjuntas")}
                                </span>
                                <span className="file__name">
                                  {t("Ver imágenes")}
                                </span>
                              </p>
                            </div>
                          )}
                          {notification.files &&
                          notification.files.length === 0 ? (
                            <div className="file no-hover">
                              <span className="file__icon">
                                <IonIcon
                                  aria-hidden="true"
                                  ios={documentAttachOutline}
                                  md={documentAttachOutline}
                                ></IonIcon>
                              </span>
                              <p className="file__info">
                                <span className="file__type">
                                  {t("No hay documentos adjuntos")}
                                </span>
                              </p>
                            </div>
                          ) : (
                            <div
                              className="file"
                              onClick={() => {
                                openNotificationFilesModal({
                                  type: "documents",
                                  files: notification.files,
                                });
                              }}
                            >
                              <span className="file__icon">
                                <IonIcon
                                  aria-hidden="true"
                                  ios={documentAttachOutline}
                                  md={documentAttachOutline}
                                ></IonIcon>
                              </span>
                              <p className="file__info">
                                <span className="file__type">
                                  {notification.files &&
                                    notification.files.length}{" "}
                                  {notification.files &&
                                  notification.files.length === 1
                                    ? t("documento adjunto")
                                    : t("documentos adjuntos")}
                                </span>
                                <span className="file__name">
                                  {t("Ver documentos")}
                                </span>
                              </p>
                            </div>
                          )}
                          <button
                            onClick={() => {
                              openNotificationDetailsModal(notification);
                            }}
                            type="button"
                            className="notif__open"
                          >
                            Ver más
                          </button>
                        </div>
                      </div>
                    ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </IonContent>
      <IonToast
        isOpen={needsRejectReason}
        onDidDismiss={() => setNeedsRejectReason(false)}
        message={t("Por favor, elija una razón de rechazo")}
        duration={2000}
        position="middle"
      />
    </IonPage>
  );
};

export default Notifs;
