import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { ServerUrlContext } from './ServerUrlContext';

export const ServerUrlProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [serverUrl, setServerUrl] = useState<string>(''); // Update the type to string

    // TODO: ¿Coger config.json solamente cuando no esté cargado en localStorage?

    useEffect(() => {
        const fetchServerUrl = async () => {
            const { data } = await axios.get('/config.json');
            
            console.log(`ServerUrlProvider: serverUrl: ${data.SERVER_URL}`);
            

          if (data.SERVER_URL === 'local') {
            if (navigator.userAgent.toLowerCase().includes('android')) {
              setServerUrl('http://10.0.2.2:8001');
            } else {
              setServerUrl('http://127.0.0.1:8001');
            }
            return;
          } else {
            setServerUrl(data.SERVER_URL);
          }
        };

        
        fetchServerUrl();
    }, []);

    return (
        <ServerUrlContext.Provider value={serverUrl}>
            {children}
        </ServerUrlContext.Provider>
    );
};